import * as React from 'react'
import { Link } from 'gatsby'
import { Flex, Button, Text, Box } from '@chakra-ui/react'

import Layout from '../layout'

// styles
const pageStyles = {
	color: '#232129',
	padding: '96px',
	fontFamily: '-apple-system, Roboto, sans-serif, serif',
	textAlign: 'center'
}
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
	maxWidth: 320
}

const paragraphStyles = {
	marginBottom: 48
}
const codeStyles = {
	color: '#8A6534',
	padding: 4,
	backgroundColor: '#FFF4DB',
	fontSize: '1.25rem',
	borderRadius: 4
}

const NotFoundPage = () => {
	return (
		<Layout title="Whoops! Nothing to see here folks.">
			<main style={pageStyles}>
				<title>Not found</title>
				<Text as="h1" variant="titlePL">
					Whoops! Nothing to see here folks.
				</Text>
				<br />

				<Button
					mt="8px"
					as={Link}
					to="/"
					variant="primary"
					type="submit"
				>
					Go Back Home
				</Button>
			</main>
		</Layout>
	)
}

export default NotFoundPage
